import assessments from './assessments'
import compliance from './compliance'
import countries from './countries'
import dashboard from './dashboard'
import extensions from './extensions'
import feedback from './feedback'
import files from './files'
import inventories from './inventories'
import jira from './jira'
import mycontrols from './mycontrols'
import pentesting from './pentesting'
import program from './program'
import projects from './projects'
import questionnaire from './questionnaire'
import reporting from './reporting'
import riskregister from './riskregister'
import selfassessments from './selfassessments'
import times from './times'
import unitedStates from './unitedStates'
import vendormanagement from './vendormanagement'
import vulnerability from './vulnerability'
import wiki from './wiki'
import StringHelpers from '../../factories/StringHelpers'

export default {
  ...assessments,
  ...compliance,
  ...countries,
  ...dashboard,
  ...extensions,
  ...files,
  ...feedback,
  ...inventories,
  ...jira,
  ...mycontrols,
  ...pentesting,
  ...program,
  ...projects,
  ...questionnaire,
  ...reporting,
  ...riskregister,
  ...selfassessments,
  ...times,
  ...unitedStates,
  ...vendormanagement,
  ...vulnerability,
  ...wiki,

  globalReset: false,
  isLoading: true,
  isInitProcessing: false,
  isLoggedIn: false,
  isDarkMode: false,
  isLeftNavExpanded: false,
  isStickyNotesOpen: false,
  stickyNotesIncludeDeleted: false,
  featureFlags: null,
  isRedirectingValid: false,
  redirectUrl: '/',

  orgIndependence: false,
  oauthBindings: {
    google: false,
    o365: false,
    okta: false,
  },

  pageTitle: 'risk3sixty',
  usersInPage: [],

  modulePermissionList: [],
  internalUsers: [],

  auth: {
    user: null, // Object that holds the authenticated users primary info (name, email, etc.)
    personalizations: null,
    userNavItems: null,
    teamUserModulePermissions: null,
  },

  pgp: {
    key: null,
  },

  queueSummary: null, // High-level info about queue counts the user has access to
  unreadSecureMessages: [],

  session: null,

  tags: [],
  r3sTags: [],

  team: {
    enumDebounce: false,
    enums: [],
    users: [],
    teamAndInternalUsers: [],
    contactUsers: [],
    uploadPortalUsers: [],
    // policyPortalUsers: [],
    distributionGroups: [],
    stickyNotes: [],
  },

  awsKeys: [],

  alerts: [],

  userTypes: [
    { val: 'external', text: 'Client User', permissionCode: 0 },
    { val: 'internal', text: 'Internal User', permissionCode: 100 },
    {
      val: 'internal_leadership',
      text: 'Internal Leadership Team',
      permissionCode: 500,
    },
    { val: 'superuser', text: 'Super User', permissionCode: 1000 },
    // { val: 'policyportal', text: 'Policy Portal User', permissionCode: 0 },
    // { val: 'uploadportal', text: 'Upload Portal User', permissionCode: 0 },
  ],

  userRoles: [
    { val: 'analyst', text: 'Analyst', permissionCode: 2 },
    { val: 'manager', text: 'Manager', permissionCode: 3 },
    { val: 'admin', text: 'Admin', permissionCode: 4 },
    // { val: 'portal', text: 'Portal', permissionCode: 0 },
  ],

  mainNotification: null, // String that shows a notification to the user when they log in
  path: null, // The full path we're currently on

  getPrettyJson(obj) {
    try {
      obj = typeof obj === 'string' ? JSON.parse(obj) : obj

      return JSON.stringify(obj, null, 2)
        .replace(/\n|\r\n/g, '<br>')
        .replace(/\s{2}/g, '&nbsp;&nbsp;')
    } catch (err) {
      return obj
    }
  },

  getUserRecord(userId) {
    try {
      const state = this
      return (
        [
          ...state.internalUsers,
          ...state.team.teamAndInternalUsers,
          ...state.team.uploadPortalUsers,
        ].find((u) => u.id == userId) || {}
      )
    } catch (err) {
      return {}
    }
  },

  getModuleNameFromNavPermission(perm) {
    const name = StringHelpers.titleCase(
      perm
        .split('_')
        .slice(1, perm.split('_').length - 1)
        .join(' ')
    )
    return name.length <= 3 ? name.toUpperCase() : name
  },

  getUserName(userId, userAry = null) {
    try {
      const state = this
      userAry = userAry || [
        ...state.internalUsers,
        ...state.team.teamAndInternalUsers,
        ...state.team.uploadPortalUsers,
      ]

      const user = userAry.find((u) => u.id == userId) || {}
      return user.name || user.username_email || 'N/A'
    } catch (err) {
      return userId
    }
  },

  isModuleAuthorized(item) {
    try {
      const state = this
      return state.auth.userNavItems && state.auth.userNavItems[item].canSee
    } catch (err) {
      return false
    }
  },

  isModuleGated(item) {
    try {
      const state = this
      return state.auth.userNavItems && state.auth.userNavItems[item].isGated
    } catch (err) {
      return false
    }
  },

  getPersonalizationParams(key) {
    try {
      const state = this
      const allPers = state.auth.personalizations
      const keyPers = allPers && allPers[key]
      return keyPers && keyPers.parameters
    } catch (err) {
      return null
    }
  },

  assessmentProjectGapTypes(projectId = null) {
    const assessmentState = this.assessments

    const projectGapTypes = assessmentState.projectGapTypes

    const currentProject =
      assessmentState.projects.find((p) => p.id == projectId) ||
      assessmentState.currentProject
    const engagementType = currentProject.engagement_type
    const defaultOptions = assessmentState.defaultControlTestResultOptions

    if (projectId) {
      if (projectGapTypes && projectGapTypes[projectId])
        return projectGapTypes[projectId]
    }

    if (!engagementType) return defaultOptions

    const engagementTypeConfig = assessmentState.engagementTypes.find(
      (t) => t.key === engagementType
    )
    return engagementTypeConfig && engagementTypeConfig.testOptions
      ? engagementTypeConfig.testOptions
      : defaultOptions
  },

  assessmentStatusesAndColors(projectId, type = 'evidence') {
    const state = this
    const statusKey =
      type === 'testing' ? 'allControlStatuses' : 'allRequestStatuses'
    const projectStatKey = type === 'testing' ? 'control' : 'request'

    const statuses = state.assessments.projectStatuses[projectId]
      ? state.assessments.projectStatuses[projectId][projectStatKey]
      : state.assessments[statusKey]

    const variantMap = state.dashboard.variantColors()
    const colors = statuses.reduce(
      (obj, status) => ({
        ...obj,
        [status.value]: variantMap[status.variant],
      }),
      {}
    )

    return {
      statuses: statuses.map((s) => s.value),
      rawStatuses: statuses,
      colors,
    }
  },

  getTeamLogo(teamRecord) {
    const state = this
    if (!teamRecord.logo_file) return null

    const normalLogo = teamRecord.logo_file
    const whiteLogo = teamRecord.logo_file_white
    const finalLogo = state.isDarkMode ? whiteLogo || normalLogo : normalLogo
    return encodeURIComponent(finalLogo)
  },

  getUsersWithPerrmissionToModule(module) {
    const state = this

    const usersWithPermission = (state.team.teamAndInternalUsers || []).filter(
      (u) => {
        const roleHasAccess =
          u.roles
            .filter((r) => !!r)
            .filter((r) => r.role == 'admin' || r.role == 'manager').length > 0
            ? true
            : false

        const appPermissionHasAccess =
          u.app_permissions.filter((p) => p[module]).length > 0 ? true : false

        return roleHasAccess || appPermissionHasAccess
      }
    )

    return usersWithPermission
  },

  getGradeAndVariantFromScore(score) {
    if (score == null) {
      return ['N/A', 'secondary']
    } else if (score >= 90) {
      return ['A', 'success']
    } else if (score >= 80) {
      return ['B', 'primary']
    } else if (score >= 70) {
      return ['C', 'info']
    } else if (score >= 60) {
      return ['D', 'warning']
    } else {
      return ['F', 'danger']
    }
  },
}
